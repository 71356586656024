import { DriverDetailsState } from 'state/forms/driverDetails/state';
import { PersonalDetails } from 'state/policy/state';
import { CsPlaceholders, replacePlaceholdersRichText } from './replaceCsPlaceholders';

type HirerAndDriverDetails = {
  hirerFirstName?: string | null;
  hirerLastName?: string | null;
  driverName?: string | null;
};

const hirerAndDriverPlaceholders: CsPlaceholders<HirerAndDriverDetails> = {
  hirerFirstName: {
    getSubstitutionText: (hirerAndDriver: HirerAndDriverDetails): string =>
      hirerAndDriver?.hirerFirstName ?? '',
    isPii: true,
  },
  hirerLastName: {
    getSubstitutionText: (hirerAndDriver: HirerAndDriverDetails): string =>
      hirerAndDriver?.hirerLastName ?? '',
    isPii: true,
  },
  driverName: {
    getSubstitutionText: (hirerAndDriver: HirerAndDriverDetails): string =>
      hirerAndDriver?.driverName ?? '',
    isPii: true,
  },
};

const replaceAddDriverPlaceholders = (
  agreement: string,
  hirer: PersonalDetails | undefined,
  driverDetails?: DriverDetailsState | undefined
): string => {
  const replaceRichTextHirerPlaceholders = replacePlaceholdersRichText(hirerAndDriverPlaceholders, {
    hirerFirstName: hirer?.firstName,
    hirerLastName: hirer?.lastName,
    driverName: driverDetails?.fullName,
  });
  return replaceRichTextHirerPlaceholders(agreement);
};

export default replaceAddDriverPlaceholders;
