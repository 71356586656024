import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import driversService from 'apiHelpers/drivers/service';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { useErrorState } from 'state/error/actions';
import { Knockout } from 'state/error/state';
import { useAboutTheDriver } from 'state/forms/aboutTheDriver/action';
import { useClaimsHistory } from 'state/forms/claimsHistory/action';
import { useDriverDetails } from 'state/forms/driverDetails/action';
import { useDrivingOffences } from 'state/forms/drivingOffences/action';
import { useHirerOrNomineeAgreement } from 'state/forms/hirerOrNomineeAgreement/action';
import { useKnockoutOverrides } from 'state/forms/knockoutOverrides/action';
import { useLicenceDetails } from 'state/forms/licenceDetails/action';
import { useLicenceRestrictions } from 'state/forms/licenceRestrictions/action';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import { isPermanentDriver, isTemporaryDriver } from 'helpers/driverTypeHelper';
import { trackFieldError } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import usePolicyNumber from 'helpers/usePolicyNumber';
import useRequestHandler from 'helpers/useRequestHandler';
import { ConfirmationState, DriverType } from 'types/forms';
import useHirerOrNomineeAgreementQuestions from './questions';
import useHirerOrNomineeAgreementRules from './validation';

type SendButtonQuery = {
  csMotabilityGlobalConfig: {
    default_form_footer_buttons: {
      final_form_page_send_button_text: string;
      final_form_page_send_button_screen_reader_text: string;
    };
  };
};

const sendButtonQuery = graphql`
  query {
    csMotabilityGlobalConfig {
      default_form_footer_buttons {
        final_form_page_send_button_text
        final_form_page_send_button_screen_reader_text
      }
    }
  }
`;

const HirerOrNomineeAgreementForm: React.FC<{ driverType: DriverType }> = ({ driverType }) => {
  const sendButton = useStaticQuery<SendButtonQuery>(sendButtonQuery).csMotabilityGlobalConfig
    .default_form_footer_buttons;
  const [hirerOrNomineeAgreement, updateHirerOrNomineeAgreement] = useHirerOrNomineeAgreement();
  const [, updateErrorState] = useErrorState();
  const questions = useHirerOrNomineeAgreementQuestions(driverType);
  const rules = useHirerOrNomineeAgreementRules(driverType);
  const { getError, validateOnSubmit } = useValidation(
    hirerOrNomineeAgreement,
    rules,
    trackFieldError
  );
  const [aboutTheDriver] = useAboutTheDriver();
  const [driverDetails] = useDriverDetails();
  const [drivingOffences] = useDrivingOffences();
  const [licenceDetails] = useLicenceDetails();
  const [claimsHistory] = useClaimsHistory();
  const [licenceRestrictions] = useLicenceRestrictions();
  const [replaceDriver] = useReplaceDriver();
  const [knockoutOverrides] = useKnockoutOverrides();
  const handleRequest = useRequestHandler();
  const policyNumber = usePolicyNumber();

  const finalFormPage = isPermanentDriver(driverType);

  const getBackNavigationUrl = (): string => {
    if (isTemporaryDriver(driverType)) {
      return getFormPageRoute(driverType, FormPage.LicenceRestrictions);
    }
    return getFormPageRoute(driverType, FormPage.Summary);
  };

  const driverUpdateRequest = (): Promise<void> => {
    return !replaceDriver.replacedDriverRef
      ? driversService.addPermanentDriverRequest(
          policyNumber,
          aboutTheDriver,
          driverDetails,
          claimsHistory,
          drivingOffences,
          licenceDetails,
          licenceRestrictions,
          knockoutOverrides
        )
      : driversService.replacePermanentDriverRequest(
          policyNumber,
          aboutTheDriver,
          driverDetails,
          claimsHistory,
          drivingOffences,
          licenceDetails,
          licenceRestrictions,
          replaceDriver,
          knockoutOverrides
        );
  };

  const successNavigation = async (): Promise<void> => {
    if (finalFormPage) {
      await handleRequest(async () =>
        driverUpdateRequest().then(() => {
          const confirmationState: ConfirmationState = {
            driverType,
            coverStartDate: new Date(),
          };
          navigate(getFormPageRoute(driverType, FormPage.Confirmation), {
            state: {
              confirmationState,
            },
          });
        })
      );
    } else {
      navigate(getFormPageRoute(driverType, FormPage.Summary));
    }
  };

  const handleSuccess = async (): Promise<void> => {
    if (hirerOrNomineeAgreement.acceptTerms) {
      await successNavigation();
    } else {
      updateErrorState({
        hasErrored: true,
        knockout: Knockout.HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS,
      });
    }
  };

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField question={questions.acceptTerms} errorText={getError('acceptTerms')}>
        <BooleanRadioInput
          id="acceptTerms"
          value={hirerOrNomineeAgreement.acceptTerms}
          onChange={(newValue) => {
            updateHirerOrNomineeAgreement({ acceptTerms: newValue });
          }}
        />
      </QuestionField>
      <FormFooter
        backButton={{
          onClick: () => navigate(getBackNavigationUrl()),
        }}
        nextButton={
          finalFormPage
            ? {
                text: sendButton.final_form_page_send_button_text,
                screenReaderText: sendButton.final_form_page_send_button_screen_reader_text,
              }
            : undefined
        }
      />
    </form>
  );
};

export default HirerOrNomineeAgreementForm;
