import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { CsDriverType, CsErrorsMissingOnly } from 'types/contentStack';
import { DriverType } from 'types/forms';

type CsHirerOrNomineeAgreementErrorMessages = {
  allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate: {
    nodes: { driver_type: CsDriverType; accept_terms: CsErrorsMissingOnly }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate {
      nodes {
        driver_type
        accept_terms {
          error_messages {
            missing
          }
        }
      }
    }
  }
`;

const useAgreementRules = (
  driverType: DriverType
): ValidationRules<{ acceptTerms: boolean | undefined }> => {
  const errorMessages = useStaticQuery<CsHirerOrNomineeAgreementErrorMessages>(query);
  const hirerAgreementQuestions = errorMessages.allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!hirerAgreementQuestions) {
    throw new Error(`Driving offences questions missing for ${driverType}`);
  }

  return {
    acceptTerms: [required(hirerAgreementQuestions.accept_terms.error_messages.missing)],
  };
};

export default useAgreementRules;
