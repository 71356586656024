import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import React from 'react';
import { StyledCard } from './styles';

const AgreementCard: React.FC<{ agreement: string }> = ({ agreement }) => {
  return (
    <Grid alignLeft>
      <GridItem desktop={10} tabletLandscape={10}>
        <StyledCard html={agreement} />
      </GridItem>
    </Grid>
  );
};

export default AgreementCard;
