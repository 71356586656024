import { graphql, useStaticQuery } from 'gatsby';

type BooleanAnswerText = {
  csMotabilityGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
    };
  };
};

const booleanAnswerTextQuery = graphql`
  query {
    csMotabilityGlobalConfig {
      boolean_answer_text {
        yes
        no
      }
    }
  }
`;

const useBooleanAnswerText = (): { yes: string; no: string } => {
  return useStaticQuery<BooleanAnswerText>(booleanAnswerTextQuery).csMotabilityGlobalConfig
    .boolean_answer_text;
};

export default useBooleanAnswerText;
