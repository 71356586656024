import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { Location } from 'history';
import React from 'react';
import { useDriverDetails } from 'state/forms/driverDetails/action';
import { usePolicyState } from 'state/policy/state';
import AgreementCard from 'components/AgreementCard';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import HirerOrNomineeAgreementForm from 'components/Forms/HirerOrNomineeAgreement';
import Layout from 'components/Layout';
import { formNameStepMapping } from 'helpers/formNameStepMapping';
import replaceAddDriverPlaceholders from 'helpers/placeholders/addDriverPlaceholders';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

export type HirerAgreementData = {
  meta_title: string;
  heading: string;
  subheading: string;
  agreement: string;
};

type HirerAgreementProps = {
  data: HirerAgreementData;
  location: Location;
  driverType: DriverType;
  formPageName: keyof typeof formNameStepMapping;
};

const HirerAgreementBaseComponent: React.FC<HirerAgreementProps> = ({
  data,
  location,
  driverType,
  formPageName,
}) => {
  useFormRedirect();
  const { policy } = usePolicyState();
  const [driverDetails] = useDriverDetails();
  return (
    <FormPageWrapper
      location={location}
      title={data.meta_title}
      formPageName={formPageName}
      driverType={driverType}>
      <Layout pageType="form" meta={{ title: data.meta_title }}>
        <FormHeading text={data.heading} />
        <RichText html={data.subheading} />
        <AgreementCard
          agreement={replaceAddDriverPlaceholders(
            data.agreement,
            policy?.personalDetails,
            driverDetails
          )}
        />
        <HirerOrNomineeAgreementForm driverType={driverType} />
      </Layout>
    </FormPageWrapper>
  );
};

export default HirerAgreementBaseComponent;
