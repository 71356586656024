import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsDriverType, CsQuestion } from 'types/contentStack';
import { DriverType, Question } from 'types/forms';

export type HirerOrNomineeAgreementQuestions = {
  acceptTerms: Question;
};

type CsHirerOrNomineeAgreementQuestions = {
  allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate: {
    nodes: { driver_type: CsDriverType; accept_terms: CsQuestion }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate {
      nodes {
        driver_type
        accept_terms {
          main_details {
            question_text
            explanatory_text
            tooltip {
              body
              button_text {
                open
                close
              }
            }
            alert_text
          }
        }
      }
    }
  }
`;

const useAgreementQuestions = (driverType: DriverType): HirerOrNomineeAgreementQuestions => {
  const { allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate } = useStaticQuery<
    CsHirerOrNomineeAgreementQuestions
  >(query);
  const { processQuestion } = useQuestionProcessor();
  const hirerAgreementQuestions = allCsAddDriverHirerOrNomineeAgreementQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!hirerAgreementQuestions) {
    throw new Error(`Hirer agreement questions missing for ${driverType}`);
  }

  return {
    acceptTerms: processQuestion(hirerAgreementQuestions.accept_terms),
  };
};

export default useAgreementQuestions;
