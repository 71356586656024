import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, { spacingWithBorder } from '@rsa-digital/evo-shared-components/helpers/spacing';
import { coreLightGrey } from '@rsa-digital/evo-shared-components/theme/Motability/colors';
import styled from 'styled-components';

export const StyledCard = styled(RichText)`
  padding: ${spacingWithBorder(2, 1)} ${spacingWithBorder(3.5, 1)};
  border: 1px solid ${coreLightGrey};
  border-radius: ${({ theme }) => theme.cardRadius};
  margin-bottom: ${spacing(3.5)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacingWithBorder(3, 1)} ${spacingWithBorder(3.5, 1)};
    margin-bottom: ${spacing(4)};
  `}
`;
