import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { trackRadioButtonClick } from 'helpers/eventTracking';
import useBooleanAnswerText from 'helpers/useBooleanAnswerText';

type BooleanRadioInputProps = {
  id: string;
  value: boolean | undefined;
  labelId?: string;
  onChange: (newValue: boolean) => void;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
};

const yesValue = 'yes';
const noValue = 'no';

const valueToString = (value: boolean | undefined): string => {
  if (value === undefined) {
    return '';
  }
  return value ? yesValue : noValue;
};

const BooleanRadioInput: InputComponent<BooleanRadioInputProps> = ({
  id,
  value,
  onChange,
  labelId,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy,
}) => {
  const { yes, no } = useBooleanAnswerText();
  return (
    <RadioInput
      id={id}
      options={[
        { name: yes, value: yesValue },
        { name: no, value: noValue },
      ]}
      value={valueToString(value)}
      onChange={(e) => {
        onChange(e.target.value === yesValue);
        trackRadioButtonClick(id, e.target.value === yesValue ? yesValue : noValue);
      }}
      labelId={labelId}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
    />
  );
};

BooleanRadioInput.isFieldSet = true;

export default BooleanRadioInput;
